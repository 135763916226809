import React from 'react';

import { Row, Col, Card } from 'antd';
import VehicleDistributionChart from "./charts/VehicleDistributionChart";
import { Vehicles, Equipment } from '../utils/em-client';
import { Projects, Companies, Departments } from '../utils/st-client';

import EquipmentDistributionChart from "./charts/EquipmentDistributionChart";

import Auth from '../utils/Auth';
import {sort} from "../utils/sorting";

export default class Dashboard extends React.Component {

  constructor(props){
    super(props);

    this.state = { vehicles: [], equipment: [], departments: [], company: [], projects: [] };
  }

  componentDidMount() {

    const companyId = Auth.getCompany();
    const departments = Auth.getDepartments();

    Vehicles.getAll(companyId, departments, null).then(result => {
      this.setState({ vehicles: result });
    }).catch(error => {
      console.error(error);
      this.setState({ vehicles: [] });
    });

    Equipment.getAll(companyId, departments, null).then(result => {
      this.setState({ equipment: result });
    }).catch(error => {
      console.error(error);
      this.setState({ equipment: [] });
    });

    Departments.getAll().then(result => {
      console.log(result);
      this.setState({ departments: result });
    }).catch(error => {
      console.error(error);
    });

    Companies.get(companyId).then(result => {
      this.setState({ company: result });
    }).catch(error => {
      console.error(error);
    });

    Projects.getAll().then(result => {
      console.log(result);
      this.setState({ projects: result });
    }).catch(error => {
      console.error(error);
    });

  }

  renderWelcome() {

    const departments = Auth.getDepartments();
    const projects = Auth.getProjects();

    const companyTitle = this.state.company ? this.state.company.title : "";
    const departmentsText = this.state.departments.filter(e => departments.includes(e._id)).map(e => e.title).join(", ");
    const projectsHtml = this.state.projects.filter(e => projects.includes(e._id)).sort((a,b) => sort(a.title,b.title)).map(e => <li>{e.title}</li>);

    return (
      <Card title="Velkommen til EquipmentManager">
        <p>
          Velkommen til EquipmentManager (EM). Bruk denne portalen til å holde oversikt over utstyr og kjøretøy som er under ditt ansvarsområde.
        </p>

        <p>Du har tilgang til følgende data:</p>
        <ul>
          <li><span style={{ fontWeight: "bold" }}>Firma: </span>{companyTitle}</li>
          <li><span style={{ fontWeight: "bold" }}>Avdelinger: </span>{departmentsText}</li>
        </ul>

        <p>Du er registrert som ansvarlig for følgende prosjekter/kontrakter:</p>
        <ul>
          {projectsHtml}
        </ul>
      </Card>
    );
  }

  render() {
    return(
      <div>
        <Row gutter={20}>
          <Col span={12}>
            <Row>
              <Col span={24}>
                <VehicleDistributionChart title="Status på kjøretøy i din avdeling" vehicles={this.state.vehicles}/>
              </Col>
              <Col span={24} style={{ marginTop: "20px" }}>
                <EquipmentDistributionChart title="Status på utstyr i din avdeling" equipment={this.state.equipment}/>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            { this.renderWelcome() }
          </Col>
        </Row>
      </div>
    );
  }
}