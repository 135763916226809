import React from 'react';

import { Layout, Row, Col } from 'antd';

const { Footer } = Layout;

export default class CustomFooter extends React.Component {

  render() {

    const footerStyle = { backgroundColor: "#2c3e50", paddingLeft: "40px", paddingRight: "40px" };

    const titleStyle = { color: "#FFFFFF"};

    const listStyle = { color: "#FFFFFF", listStyleType: "none", padding: "5px 0 5px 0" };

    const linkStyle = { color: "#FFFFFF" };

    return(
      <Footer style={footerStyle}>
        <Row>
          <Col span={12}>
            <h3 style={titleStyle}>Hjelp og støtte</h3>
            <ul style={listStyle}>
              <li>Epost: support@roadmanager.io</li>
              <li>Tlf: 960 08 831</li>
            </ul>
          </Col>
        </Row>
      </Footer>
    );
  }
}