import React from 'react';


import { Menu, Icon, Layout, Avatar, Row, Col, Dropdown } from 'antd';
import Auth from '../utils/Auth';

const { Header } = Layout;

export default class CustomHeader extends React.Component {

  render() {

    const headerStyle = { backgroundColor: "#f1c40f"};
    const linkStyle = { color: "black"};
    const menu = (
      <Menu>
        <Menu.Item>
          <Icon type="logout" theme="outlined" />
          <span><a style={linkStyle} target="_blank" rel="noopener noreferrer" onClick={() => { Auth.logout() }}>Logg ut</a></span>
        </Menu.Item>
      </Menu>
    );

    return(
      <Header style={headerStyle}>
        <Row>
          <Col span={20}>
            <h3 style={{color:"white" }}>EquipmentManager</h3>
          </Col>
          <Col span={4}>
            <Dropdown overlay={menu} placement="bottomRight">
              <Avatar shape="square" icon="user" style={{ float: "right", marginTop: "15px" }}/>
            </Dropdown>
          </Col>
        </Row>
      </Header>
    );
  }
}